import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingOverlay } from '../../components/loading/Loading';
import { useAPI } from '../../context/APIContext';
import EmailSending from './EmailSending';
import EmailVerifying from './EmailVerifying';
import apiUri from '../../config/Api.js';
import PasswordResetting from './PasswordResetting';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Map Marker
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export const PasswordForgetting = () => {
    const { loading, handleLoginStatus, post } = useAPI();
    const navigate = useNavigate();
    const [step, setStep] = useState({
        email_sending: true,
        email_verifying: false,
        password_resetting: false
    });
    const [currentStep, setCurrentStep] = useState('email_sending');
    const [email, setEmail] = useState(null);
    const [verificationCode, setVerificationCode] = useState(null);
    const [memberInfo, setMemberInfo] = useState({
        pwd: null,
        confirm_pwd: null
    });



    const handleClickBreadcrumbs = (event) => {
        event.preventDefault();
        event.stopPropagation();  // 阻止事件冒泡
        const id = event.currentTarget.id;
        setCurrentStep(id);
        if (id === "email_sending") {
            setStep({
                email_sending: true,
                email_verifying: false,
                password_resetting: false,
            });
        }
        else if (id === "email_verifying") {
            setStep({
                email_sending: true,
                email_verifying: true,
                member_creating: false
            });
        }
        else {
            setStep({
                email_sending: true,
                email_verifying: true,
                password_resetting: true,
            });
        }
    }

    const handleSendEmail = (event) => {
        SendEmail();
    }

    const SendEmail = async () => {
        await post(apiUri.SendEmail,
            { email: email, type: 2 },
            (resData) => {
                setCurrentStep("email_verifying");
                setStep({
                    email_sending: true,
                    email_verifying: true,
                    password_resetting: false
                });
            });
    }

    const handleVerifyEmail = (event) => {
        VerifyEmail();
    }

    const VerifyEmail = async () => {
        await post(apiUri.VerifyEmail,
            { email: email, verification_code: verificationCode, type: 2 },
            (resData) => {
                setCurrentStep("password_resetting");
                setStep({
                    email_sending: true,
                    email_verifying: true,
                    password_resetting: true
                });
            });
    }

    const handleResetPassword = (event) => {
        ResetPassword();
    }

    const ResetPassword = async () => {
        const resData = await post(apiUri.forgetPassword,
            { email: email, verification_code: verificationCode, pwd: memberInfo.pwd });

        if (resData.code === 0) {
            LoginSuccess(resData);
        }
        else {
            navigate('/account', { replace: false });
        }
    }

    const LoginSuccess = (resData) => {
        sessionStorage.setItem("token", resData.token);
        sessionStorage.setItem("latest_access_type", resData.latest_access_type);
        sessionStorage.setItem("role_ids", resData.role_ids);
        handleLoginStatus(true);
        navigate('/', { replace: true });
    }

    return (
        <>
            {loading && <LoadingOverlay />}
            <ThemeProvider theme={defaultTheme}>
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={7}
                        sx={{
                            backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                        <Box
                            sx={{
                                my: 2,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                忘記密碼
                            </Typography>
                            <Box
                                sx={{
                                    mt: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'left',
                                }}
                            >
                                <Breadcrumbs aria-label="breadcrumb">
                                    {step.email_sending && step.email_sending === true &&
                                        <Link underline="hover" color="inherit" href="#" id="email_sending" onClick={handleClickBreadcrumbs}>
                                            信箱
                                        </Link>
                                    }
                                    {step.email_verifying && step.email_verifying === true &&
                                        <Link underline="hover" color="inherit" href="#" id="email_verifying" onClick={handleClickBreadcrumbs}>
                                            驗證碼
                                        </Link>
                                    }
                                    {step.password_resetting && step.password_resetting === true &&
                                        <Link underline="hover" color="inherit" href="#" id="password_resetting" onClick={handleClickBreadcrumbs}>
                                            重設密碼
                                        </Link>
                                    }
                                </Breadcrumbs>
                            </Box>
                            {currentStep === 'email_sending' &&
                                <EmailSending
                                    email={email} onSendEmail={handleSendEmail} setEmail={setEmail}
                                />
                            }
                            {currentStep === 'email_verifying' &&
                                <EmailVerifying
                                    verificationCode={verificationCode} onVerifyEmail={handleVerifyEmail} setVerificationCode={setVerificationCode}
                                />
                            }
                            {currentStep === 'password_resetting' &&
                                <PasswordResetting
                                    memberInfo={memberInfo} onResetPassword={handleResetPassword} setMemberInfo={setMemberInfo}
                                />
                            }
                            <Box sx={{ width: '100%' }}>
                                <Copyright sx={{ mt: 5 }} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        </>
    );
}

export default React.memo(PasswordForgetting);
