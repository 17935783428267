import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import useCheckDeviceMobile from "../../hook/useDeviceDetector";
import { useData } from '../../context/DataContext';

const LeafletMap = () => {
	const { condition, result } = useData();
	const [map, setMap] = useState(null);
	const [layerGroup, setLayerGroup] = useState(null);
	const zoomLevel = 16;
	const isMobile = useCheckDeviceMobile();

	useEffect(() => {
		const newMap = L.map('map').setView([25.04293616279186, 121.56951109666868], zoomLevel);

		L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			minZoom: 16,
			maxZoom: 19,
			attribution: '© OpenStreetMap contributors'
		}).addTo(newMap);

		var zoomControl = L.control.zoom({ position: 'bottomright' });
		zoomControl.addTo(newMap);

		newMap.removeControl(newMap.zoomControl);

		// 建立 LayerGroup
		const newLayerGroup = L.layerGroup().addTo(newMap);
		setLayerGroup(newLayerGroup);
		setMap(newMap);
	}, [])

	useEffect(() => {
		if (result && (result.env_infos !== null || result.places !== null)) {

			layerGroup.clearLayers();

			L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				minZoom: 16,
				maxZoom: 19,
				attribution: '© OpenStreetMap contributors'
			}).addTo(map);

			let centerLng = result.center_lng;
			if (isMobile === false)
				centerLng = centerLng - 0.007;
			map.setView([result.center_lat, centerLng], zoomLevel);

			let centerMarker = L.marker([result.center_lat, result.center_lng]).addTo(map);
			centerMarker.bindPopup(`<b>${condition.county}${condition.district}${condition.address}</b>`).openPopup();
			layerGroup.addLayer(centerMarker);

			if (result.env_infos !== null) {
				result.env_infos.forEach((place) => {
					var envIcon = L.icon({
						iconUrl: `/marker/${place.name}.svg`,
						iconSize: [30, 90], // size of the icon
					});
					const envPlaceMarker = L.marker([place.lat, place.lng], { icon: envIcon }).bindPopup(place.fullname);
					layerGroup.addLayer(envPlaceMarker);
				});
			}

			if (result.places !== null) {
				result.places.forEach((place) => {

					let iconName = "實價登錄.svg";
					var priceGoveIcon = L.icon({
						iconUrl: `/marker/${iconName}`,
						iconSize: [30, 90], // size of the icon
					});
					const popup = `${place.county}${place.district}${place.address}\n${place.age}年\n${place.distance}`;
					const placeMarker = L.marker([place.lat, place.lng], { icon: priceGoveIcon }).bindPopup(popup);

					// // 為標記添加 onClick 事件
					// placeMarker.on('click', function() {
					// 	alert('Marker clicked!');
					// });

					layerGroup.addLayer(placeMarker);
				});
			}

			var circle = L.circle([result.center_lat, result.center_lng], {
				color: '#DAA520',
				weight: 2,
				fillColor: '#FFFF00',
				fillOpacity: 0.3,
				radius: condition.radius
			});
			layerGroup.addLayer(circle);
		}
	}, [condition, result])
}

export default React.memo(LeafletMap);