import React, { createContext, useContext, useEffect, useState } from 'react';
import { apiUris } from '../config/Api';
import { useAPI } from './APIContext';
import { LoadingOverlay } from '../components/loading/Loading';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const { post, loginStatus } = useAPI();
    const [categoryList, setCategoryList] = useState(null);
    const [categoryACList, setCategoryACList] = useState(null);
    const [categoryFilterItem, setCategoryFilterItem] = useState(null);
    const [dataContextLoading, setDataContextLoading] = useState(true);
    const [condition, setCondition] = useState(null);
    const [result, setResult] = useState(null);
    const [report, setReport] = useState(null);

    //let query = useQuery();

    useEffect(() => {
        getEnvFilterItem();
    }, []);

    useEffect(() => {
        if (loginStatus) { //需登入才可以呼叫的API
            queryEnvCategory();
        }
    }, [loginStatus]);

    // const useQuery = () => {
    //     const { search } = useLocation();
    //     const query = React.useMemo(() => new URLSearchParams(search), [search]);
    //     return query;
    // }

    useEffect(() => {
        checkInitalData();
    }, [categoryList, categoryACList, categoryFilterItem]);

    const checkInitalData = () => {
        if (categoryList && categoryACList && categoryFilterItem) {
            setDataContextLoading(false);
        }
    }

    const queryEnvCategory = async () => {
        await post(apiUris.getWithoutRootEnvCategory, null, handleResData);
    }

    const handleResData = (resData) => {
        let newCategoryList = [{ category: '請選擇', value: "null", name: "" }];
        let newCategoryACList = []
        resData.list.map((item) => {
            newCategoryACList.push({
                category: item.category,
                value: item.category,
                name: item.name
            });
        });
        setCategoryList([...newCategoryList, ...newCategoryACList]);
        setCategoryACList(newCategoryACList);
    }

    const getEnvFilterItem = async () => {
        post(apiUris.getEnvFilterItem, null, StoreEnvFilterItemResData);
    }

    const StoreEnvFilterItemResData = (resData) => {
        setCategoryFilterItem(resData.filter_item);
    }

    const storeCondition = (newCondition) => {
        console.log(newCondition);
        setCondition(newCondition);
    }

    const storeResult = (newResult) => {
        console.log(newResult);
        setResult(newResult);
    }

    const storeReport = (newReport) => {
        console.log(newReport);
        setReport(newReport);
    }

    return (
        <DataContext.Provider value={{
            // useQuery,
            categoryList,
            categoryACList,
            categoryFilterItem,
            condition,
            result,
            report,
            storeCondition,
            storeResult,
            storeReport
        }}>
            {loginStatus ? (dataContextLoading ? <LoadingOverlay /> : children) : children}
        </DataContext.Provider>
    )
}

export const useData = () => useContext(DataContext);