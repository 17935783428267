import AccountCircle from '@mui/icons-material/AccountCircle';
import { Box } from '@mui/material';
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from "@mui/material/Toolbar";
import React, { createContext, useContext, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { apiUris } from '../config/Api';
import { useAPI } from './APIContext';

const appBarStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '60px', // 可根据需要调整 AppBar 的高度
    // backgroundColor: '#f0f0f0',
    // 添加其他样式，如阴影等
};

const menuStyle = {
    display: 'flex',
    flexGrow: 1,
}

const accountStyle = {
    display: 'flex',
};

const LayoutContext = createContext();

export const LayoutProvider = ({ children }) => {
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { loginStatus, handleLoginStatus, post } = useAPI();
    const navigate = useNavigate();

    useEffect(() => {
        const token = sessionStorage.getItem("token");
        if (token !== null && token !== "") {
            handleLoginStatus(true);
        }
    }, []);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logout();
    }

    const logout = async () => {
        const res = await post(apiUris.logout);
        if (res.code === 0) {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("latest_access_type");
            sessionStorage.removeItem("role_ids");
            handleLoginStatus(false);
            navigate('/account', { replace: false });
        }
    }

    return (
        <LayoutContext.Provider value={{}}>
            <AppBar sx={appBarStyle}>
                <Toolbar>
                    <Box sx={menuStyle}>
                        <Button color="inherit" component={Link} to="/">
                            <img width="42px" src="/logo.svg" />
                        </Button>
                        {/* <Button color="inherit" component={Link} onClick={props.handleScrollToMapChip} to="/">地圖</Button>
                            <Button color="inherit" component={Link} onClick={props.handleScrollToEnvChip} to="/">機能</Button>
                            <Button color="inherit" component={Link} onClick={props.handleScrollToPriceChip} to="/">實價登錄</Button> */}
                        <Button color="inherit" component={Link} to="/TempEnv">待新增機能</Button>
                    </Box>
                    <Box sx={accountStyle}>
                        <Button color="inherit" component={Link} to="/Pricing">
                            <img width="30px" src="/cart-50.png"></img>
                        </Button>
                        {
                            loginStatus == true
                                ?
                                <div>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                    >
                                        <AccountCircle />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                                        <MenuItem onClick={handleClose}>帳戶</MenuItem>
                                        <MenuItem onClick={handleLogout}>登出</MenuItem>
                                    </Menu>
                                </div>
                                : <Button color="inherit" component={Link} to="/Account">登入</Button>
                        }
                    </Box>
                </Toolbar>
            </AppBar>
            <div style={{ position: 'relative', marginBottom: '60px' }}></div>
            {children}
        </LayoutContext.Provider>
    )
}

export const useData = () => useContext(LayoutContext);