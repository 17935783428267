
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, IconButton, useTheme } from '@mui/material';
import Chip from '@mui/material/Chip/Chip.js';
import Divider from '@mui/material/Divider/Divider.js';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import '../../css/styles.css';
import { useData } from '../../context/DataContext';
import { useAPI } from '../../context/APIContext';
import { LoadingOverlay } from '../../components/loading/Loading.jsx';
import Typography from '@mui/material/Typography';
import PriceGovResultDetail from './PriceGovResultDetail';
import apiUris from '../../config/Api';

const chipStyle = {
    fontSize: '14px',
    backgroundColor: '#1976d2',
    color: '#FFFFFF'
};

// 定制 Chip，使其在活動狀態下不改變外觀
const CustomChip = styled(Chip)({
    '&:hover': {
        backgroundColor: '#97CBFF', // 保持繼承的背景色，即不變化
        color: '#FFFFFF' // 保持繼承的文字顏色，即不變化
    }
});

export const SearchResult = () => {
    const { loading, post } = useAPI();
    const { result } = useData();
    const [envCollapseOpen, setEnvCollapseOpen] = useState(true);
    const [priceGovOpen, setPriceGovOpen] = useState(true);
    const [envPlaces, setEnvPlaces] = useState(null);
    const [priceGovPlaces, setPriceGovPlaces] = useState(null);

    const envColumnsWidth = {
        //window.innerWidth 裝置的視窗的內部寬度px（不包括滾動條）
        //0.45代表此component 佔據視窗的比例
        no: window.innerWidth * 0.46 * 0.125,
        //name: window.innerWidth * 0.46 * 0.375,
        subname: window.innerWidth * 0.46 * 0.175,
        category: window.innerWidth * 0.46 * 0.2,
        distance: window.innerWidth * 0.46 * 0.25,
        time: window.innerWidth * 0.46 * 0.25,
    }

    const envColumns = [
        // { field: 'id', headerName: 'ID', width: 70, hide: true },
        {
            field: 'no',
            headerName: '',
            width: envColumnsWidth.no,
            sortable: false,
            renderCell: (params) => {
                // 得到所有已排序和過濾後的行 ID
                const allRows = params.api.getSortedRowIds();
                // 找出當前行 ID 在已排序列表中的索引，加 1 以顯示從 1 開始的序號
                const no = allRows.indexOf(params.id) + 1;
                return <span>{no}</span>;
            }
        },
        // { field: 'fullname', headerName: '名稱', width: envColumnsWidth.name, disableColumnMenu: true },
        { field: 'subname', headerName: '名稱', width: envColumnsWidth.subname, disableColumnMenu: true },
        { field: 'category', headerName: '類別', width: envColumnsWidth.category, disableColumnMenu: true },
        { field: 'distance', headerName: '距離(公尺)', width: envColumnsWidth.distance, type: 'number' },
        { field: 'time', headerName: '時間(分鐘)', width: envColumnsWidth.time, type: 'number' },
    ];

    useEffect(() => {
        if (result !== null && result.env_infos !== null) {
            setEnvPlaces(result.env_infos.map(item => {
                const place = {
                    id: item.id,
                    name: item.name,
                    fullname: item.fullname,
                    position: { lat: item.lat, lng: item.lng },
                    category: item.category,
                    subname: item.subname,
                    distance: item.distance,
                    time: 5
                };
                return place;
            }));
        }
        if (result !== null && result.places !== null) {
            setPriceGovPlaces(result.places.map(item => {
                const place = {
                    id: item.id,
                    county: item.county,
                    district: item.district,
                    address: item.address,
                    fullname: `${item.county}${item.district}${item.address}`,
                    position: { lat: item.lat, lng: item.lng },
                    community_name: item.community_name,
                    age: item.age,
                    distance: item.distance,
                    time: 5,
                    collapse: false,
                    list: null
                };
                return place;
            }));
        }
    }, [result])

    const handleEnvCollapseOpenToggle = (event) => {
        setEnvCollapseOpen(!envCollapseOpen);
    }

    const handlePriceGovOpenToggle = (event) => {
        setPriceGovOpen(!priceGovOpen);
    }

    const handlePriceGovDetailCollapseToggle = (event) => {
        const place_id = parseInt(event.currentTarget.dataset.id);
        const county = event.currentTarget.dataset.county;
        const district = event.currentTarget.dataset.district;
        const address = event.currentTarget.dataset.address;
        const newPriceGovPlaces = priceGovPlaces.map(item => {
            if (item.id === place_id) {
                const collapse = item.collapse === true ? false : true;
                item.collapse = collapse;
                if (collapse === true && (item.list === null || item.list.length === 0)) {
                    //非同步
                    post(apiUris.queryPriceGovByAddr, { place_id, county, district, address }, handleQueryPriceGovByAddrResult);
                    //同步
                    // queryPriceGovByAddr({ place_id, county, district, address });
                }
            }
            return item;
        });
        setPriceGovPlaces(newPriceGovPlaces);
    }

    // const queryPriceGovByAddr = async (paramsObj) => {
    //     await post(apiUris.queryPriceGovByAddr, paramsObj, handleQueryPriceGovByAddrResult);
    // }

    const handleQueryPriceGovByAddrResult = async (resData) => {
        const newPriceGovPlaces = priceGovPlaces.map(item => {
            if (item.id === resData.place_id) {
                item.list = resData.list;
            }
            return item;
        });
        setPriceGovPlaces(newPriceGovPlaces);
    }

    return (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1 }} padding={2}>
            {envPlaces && envPlaces.length > 0 &&
                <Grid item xs={12} sm={12} md={12}>
                    <Divider textAlign="center" sx={{ marginBottom: 2 }} >
                        <CustomChip label="周遭機能" sx={chipStyle} onClick={handleEnvCollapseOpenToggle} />
                        <IconButton
                            onClick={handleEnvCollapseOpenToggle}
                            sx={{ height: 30 }}>
                            {envCollapseOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Divider>
                    <Collapse in={envCollapseOpen} timeout="auto" unmountOnExit>
                        <DataGrid
                            columns={envColumns}
                            rows={envPlaces}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                        />
                    </Collapse>
                </Grid>
            }
            {priceGovPlaces && priceGovPlaces.length > 0 &&
                <Grid item xs={12} sm={12} md={12}>
                    <Divider textAlign="center" sx={{ marginBottom: 2 }}>
                        <CustomChip label="實價登錄" sx={chipStyle} onClick={handlePriceGovOpenToggle} />
                        <IconButton
                            onClick={handlePriceGovOpenToggle}
                            sx={{ height: 30 }}>
                            {priceGovOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Divider>
                    <Collapse in={priceGovOpen} timeout="auto" unmountOnExit>
                        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1 }} padding={0}>
                            {priceGovPlaces.map((item, index) => (
                                <Grid key={index} item xs={12} sm={12} md={12}>
                                    <Divider textAlign="center">
                                        <Typography
                                            sx={{ cursor: 'pointer' }}
                                            onClick={handlePriceGovDetailCollapseToggle}
                                            data-id={`${item.id}`}
                                            data-county={`${item.county}`}
                                            data-district={`${item.district}`}
                                            data-address={`${item.address}`}
                                        >
                                            {item.fullname}-{item.age}年
                                            <IconButton
                                                sx={{ height: 30 }}>
                                                {item.collapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            </IconButton>
                                        </Typography>
                                    </Divider>
                                    <Collapse in={item.collapse} timeout="auto" unmountOnExit>
                                        {loading && <LoadingOverlay />}
                                        {item.list && item.list.length > 0 &&
                                            <PriceGovResultDetail key={item.id} list={item.list} />
                                        }
                                    </Collapse>
                                </Grid>
                            ))}
                        </Grid>

                    </Collapse>
                </Grid>
            }
        </Grid>
    )
}

export default React.memo(SearchResult);