import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const TravelModel = ({ defaultValue, onInputChange }) => {
    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">交通方式</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="交通方式"
                onChange={onInputChange}
                value={defaultValue}
            >
                <MenuItem value="WALKING">步行</MenuItem>
                <MenuItem value="DRIVING">汽機車</MenuItem>
            </Select>
        </FormControl>
    );
}

export default React.memo(TravelModel);