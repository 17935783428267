import axios from "axios";
import { format } from 'react-string-format';


export function getPlaces(location, keyword)
{
    //call google place api
    const paramsObj = 
    {
        key: 'AIzaSyDOyfFLflBzHAZfH1B5nPRFHZmbnAXZDB8',
        location: location,
        radius: 300,
        language : 'zh-tw',
        keyword: keyword,
        //type: https://developers.google.com/maps/documentation/places/web-service/supported_types?hl=zh-tw
    };

    const paramsArray = [];
    for (let key in paramsObj) {
        let value = paramsObj[key];
        if(value !== null){
            paramsArray.push(`${key}=${value}`);
        }
    }

    const paramsStr = paramsArray.join('&');
    let url = 'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/nearbysearch/json?{0}';
    //let url = 'https://cors.io/?https://maps.googleapis.com/maps/api/place/nearbysearch/json?{0}';
    url = format(url,paramsStr);

    // const key = 'AIzaSyDOyfFLflBzHAZfH1B5nPRFHZmbnAXZDB8';
    // const radius = 100;
    // let url = 'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/nearbysearch/json?location={0}&radius={1}&keyword={2}&key={3}'; //"http://localhost/api/place";
    // url = format(url,location,radius,keyword,key);
    //var url = 'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=25.0338,121.5646&radius=1000&keyword=牛排&language=zh-TW&key=AIzaSyDOyfFLflBzHAZfH1B5nPRFHZmbnAXZDB8';

    var config = {
        method: 'get',
        url:url
        // headers: {
        //     // 'Content-Type':'application/json'
        //     // 'Authorization':'Bearer my-token',
        //     // 'My-Custom-Header':'foobar'
        //  },
        // body:JSON.stringify({
        //     "Location": location,
        //     "Radius": radius,
        //     "Keyword": keyword
        // })
    };

    return axios(config).then(response => {
        console.log(JSON.stringify(response.data));
        return response.data;
    })
    .catch(function (error) {
        console.log(error);
    });
}

export function getPlacesByLocal(position, address, radius, envGroupCodes)
{
    //call house api 
    const paramsObj = 
    {
        Position: position,
        Address : address,
        Radius: radius,
        EnvGroupCodes: envGroupCodes
        // Keyword: keyword,
    };
    
    let url = format('{0}/api/Environment/Query',process.env.REACT_APP_API_DOMAIN);
    console.log(url);

    return axios.post(url, paramsObj).then(response => {
        console.log(JSON.stringify(response.data));
        return response.data;
    })
    .catch(function (error) {
        console.log(error);
    });
}

export default getPlacesByLocal;