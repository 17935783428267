import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

const House = (props) => {
    const [house, setHouse] = useState(null);
    const coordinateRegex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
    //const addressRegex = /[\u4e00-\u9fff]/;

    const onChange = (event) => {
        const newHouse = event.target.value;
        let lat = null;
        let lng = null;
        let address = null;
        if (coordinateRegex.test(newHouse)) {
            const lat_lng = event.target.value.split(",");
            lat = parseFloat(lat_lng[0]);
            lng = parseFloat(lat_lng[1]);
        } else {
            address = event.target.value;
        }

        setHouse(newHouse);

        props.handleMuitChange([
            { name: "center_lat", value: lat },
            { name: "center_lng", value: lng },
            { name: "address", value: address },
        ]);

        //props.onInputChange(newHouse);
    }

    return (
        <TextField required fullWidth
            label="地址or經緯度 (必填)"
            id="house"
            name="house"
            value={house}
            onChange={onChange}
        />
    );
}

export default React.memo(House);