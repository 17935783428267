import React from 'react';
import Fab from '@mui/material/Fab';
import SearchIcon from '@mui/icons-material/Search';

function SearchFloatingBtn(props) {
  return (
    <Fab color="primary" aria-label="search" style={{ position: 'fixed', bottom: 60, right: 20 }} onClick={props.handleFabClick}>
      <SearchIcon  />
    </Fab>
  );
}

export default SearchFloatingBtn;
