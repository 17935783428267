import React, { useState, useEffect } from "react";
import GoogleMapReact from 'google-map-react';
import { format } from 'react-string-format';

export default function Map(props) {
  const defaultProps = {
    //取得社區座標
    center: {
      lat: 25.04378078876133,
      lng: 121.56957466477513
    },
    zoom: 16
  };

  const [mapObj, setMap] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [houseMarker, setHouseMarker] = useState(null);
  const [cityCircle, setCityCircle] = useState(null);
  const [options, setOptions] = useState({
    minZoom: 16 - 2,
    maxZoom: 16 + 2,
    restriction: {
      latLngBounds: {
        north: defaultProps.center.lat + 0.01,
        south: defaultProps.center.lat - 0.01,
        east: defaultProps.center.lng + 0.02,
        west: defaultProps.center.lng - 0.02,
      },
    }
  });


  const onMapLoaded = ({ map, maps }) => {
    console.log("onMapLoaded");
    setMap({ map, maps });
    setDirectionsRenderer(new maps.DirectionsRenderer({ suppressMarkers: true }));
    setDirectionsService(new maps.DirectionsService());
  }

  useEffect(() => {
    console.log("useEffect mapObj");
    if (mapObj) {
      cleanMap();
      if (props.map != null) {
        getDirections();
      }
    }
  }, [mapObj, props.map])

  const cleanMap = () => {
    if (markers.length) {
      markers.forEach((marker) => {
        marker.setMap(null);
      });
    }
    if (houseMarker) {
      houseMarker.setMap(null);
    }
    if (cityCircle) {
      cityCircle.setMap(null);
    }

    setMarkers([]);
  };

  const getDirections = () => {
    directionsRenderer.setMap(mapObj.map);

    let tempHouseMarker = new mapObj.maps.Marker({
      position: props.map.houseInfo.position,
      map: mapObj.map,
      title: "myHouse",
    });
    //讓地圖回到以house座標的中心點
    mapObj.map.setCenter(tempHouseMarker.position);
    const newOptions = {
      minZoom: options.minZoom,
      maxZoom: options.maxZoom,
      restriction: {
        latLngBounds: {
          north: props.map.houseInfo.position.lat + 0.01,
          south: props.map.houseInfo.position.lat - 0.01,
          east: props.map.houseInfo.position.lng + 0.02,
          west: props.map.houseInfo.position.lng - 0.02,
        },
      }
    };
    setOptions(newOptions);
    setHouseMarker(tempHouseMarker);

    const radius = parseInt(props.map.radius);
    let tempCityCircle = new mapObj.maps.Circle({
      strokeColor: "#55AAF0",
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: "#55AAF0",
      fillOpacity: 0.25,
      map: mapObj.map,
      center: tempHouseMarker.position,
      radius: radius,
    });
    setCityCircle(tempCityCircle);
    showMarkers();

    //checkDestination();
  };

  function checkDestination() {
    const destinationArray = [];
    props.map.places.forEach((place) => {
      destinationArray.push(place.position);
    });

    const request = {
      origins: [props.map.houseInfo.position],
      destinations: destinationArray,
      travelMode: mapObj.maps.TravelMode[props.map.travelMode],
      unitSystem: mapObj.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false,
    };

    const requestOptions = {
      mode: 'no-cors',
      method: 'GET'
    };

    const service = new mapObj.maps.DistanceMatrixService();
    service.getDistanceMatrix(request, (response, status) => {
      if (status === 'OK') {
        console.log(JSON.stringify(response));

        const tempMarkers = [];
        response.rows[0].elements.map((e, i) => {
          let distance = e.distance.text.replace(' 公里', '');
          if (parseFloat(distance) <= 0.3) {
            let markerTitle = format('{0}\n{1}', props.map.places[i].name, e.duration.text);
            let marker = new mapObj.maps.Marker({
              position: props.map.places[i].position,
              map: mapObj.map,
              title: markerTitle,
            });
            tempMarkers.push(marker);
          }
          return e;
        });
        setMarkers(tempMarkers);

      } else {
        // 處理錯誤
      }
    }, requestOptions);
  }

  function showMarkers() {
    const tempMarkers = [];
    props.map.places.forEach((place) => {
      // destinationArray.push(place.position);
      let markerTitle = format('{0}\n{1}', place.name, '路程約5分鐘內');
      const icon = `/marker/${place.env_group_code}_25.svg`;
      let marker = new mapObj.maps.Marker({
        position: place.position,
        map: mapObj.map,
        title: markerTitle,
        icon: icon
      });
      tempMarkers.push(marker);
    });

    setMarkers(tempMarkers);
  }

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '100vh', width: '100%' }} >
      {console.log("render:Map")}
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDOyfFLflBzHAZfH1B5nPRFHZmbnAXZDB8" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => onMapLoaded({ map, maps })}
        // options={{
        //   minZoom:defaultProps.minZoom,
        //   maxZoom:defaultProps.maxZoom,
        //   restriction: defaultProps.restriction
        // }}
        // onChange={handleMapChange}
        options={options}
      >
      </GoogleMapReact>
    </div>
  );
}

