import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from "react";
import { useAPI } from '../../context/APIContext';
import { useData } from '../../context/DataContext';

export const EnvCategoryAC = ({
    defCategory = '',
    defName = '',
    handleMuitChange,
    GridColSpacing = { xs: 1, sm: 2, md: 3 },
    GridItemSize = { xs: 12, sm: 6, md: 6 }
}) => {
    const { post } = useAPI();
    const { categoryACList } = useData();
    // const [categoryList, setCategoryList] = useState(null);
    const [category, setCategory] = useState('');
    const [name, setName] = useState('');

    useEffect(() => {
        // if (defCategory === undefined || defCategory === null || defCategory === '') {
        //     defCategory = '';
        // }
        setCategory(defCategory);
        setName(defName);
    }, [])

    const handleEnvCategoryOnChange = (event, newOption) => {
        const newCategory = newOption ? newOption.value : null;
        setCategory(newCategory);

        const newName = newOption ? newOption.name : "";
        setName(newName);

        handleMuitChange([
            { name: "category", value: newCategory },
            { name: "name", value: newName }
        ])
    }

    return (
        <Grid container rowSpacing={1} columnSpacing={GridColSpacing} padding={0}>
            {categoryACList &&
                <Grid item xs={GridItemSize.xs} sm={GridItemSize.sm} md={GridItemSize.md}>
                    <FormControl variant="outlined" fullWidth>
                        <Autocomplete
                            options={categoryACList}
                            getOptionLabel={(option) => option.name} // 获取选项显示文本的函数
                            // filterOptions={delayedFilter} // 设置 filterOptions 属性
                            renderInput={(params) => <TextField {...params} label="機能名稱" />}
                            value={name ? categoryACList.find(option => option.value == category) : null}
                            onChange={handleEnvCategoryOnChange}
                            noOptionsText="查無資料"
                        />
                    </FormControl>
                </Grid>
            }
            <Grid item xs={GridItemSize.xs} sm={GridItemSize.sm} md={GridItemSize.md}>
                <FormControl fullWidth>
                    <TextField fullWidth
                        required
                        disabled
                        name="category"
                        label="機能類別"
                        value={category}
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default React.memo(EnvCategoryAC);