import React, { useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { DesktopLayout } from "./DesktopLayout";
import { MobileLayout } from "./MobileLayout";
import { Alerts } from './components/alert/Alerts';
import { APIProvider } from "./context/APIContext";
import { DataProvider } from "./context/DataContext";
import useDeviceDetector from "./hook/useDeviceDetector";
import { LayoutProvider } from "./context/LayoutContext";
import { ThemeProvider } from '@mui/material/styles';
import { themeMui } from "./css/themeMui";

function useQuery() {
  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  return query;
}

const App = () => {

  let query = useQuery();

  useEffect(() => {
    // 載入 Facebook SDK
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

    // SDK 載入完成時會立即呼叫 fbAsyncInit，在這個函式中對 Facebook SDK 進行初始化
    window.fbAsyncInit = function () {
      // 初始化 Facebook SDK
      window.FB.init({
        appId: process.env.REACT_APP_FB_APP_ID,
        cookie: true,
        xfbml: true,
        version: process.env.REACT_APP_FB_APP_VERSION,
      });

      console.log('[fbAsyncInit] after window.FB.init');

      // 取得使用者登入狀態
      window.FB.getLoginStatus(function (response) {
        console.log('[refreshLoginStatus]', response);
      });

      window.FB.AppEvents.logPageView();
    };
  }, []);

  return (
    <ThemeProvider theme={themeMui}>
      <APIProvider>
        <DataProvider>
          <Alerts />
          <DesktopLayout
            query={query}
          />
        </DataProvider>
      </APIProvider>
    </ThemeProvider>
  );
}

export default App;
