import React from 'react';

const customHeight = {
    height: '91vh'
};

const PositionDisplay = () => {
    return (
        <div style={{ position: 'relative', backgroundColor: 'gray', height: '91vh', width: '100vw' }}>
            <div style={{ backgroundColor: 'blue' }}></div>
        </div>
    );
}

export default React.memo(PositionDisplay);