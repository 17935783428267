import React from 'react';
import { Alert, Snackbar, Stack } from '@mui/material';
import { useAPI } from '../../context/APIContext';

export const Alerts = () => {
    const { alert, hideAlert } = useAPI();

    return (
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={alert.open} autoHideDuration={6000} onClose={hideAlert}>
            <Alert sx={{ width: '30vw', backgroundColor: 'white', whiteSpace: 'pre-line' }} variant="outlined" onClose={hideAlert} severity={alert.type} >
                {alert.message}
            </Alert>
        </Snackbar>
    );
};

export default Alerts;
