import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React from 'react';

export const PasswordResetting = ({ memberInfo, onResetPassword, setMemberInfo }) => {
    return (
        <Box noValidate sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                name="pwd"
                label="密碼"
                type="password"
                id="pwd"
                value={memberInfo.pwd}
                onChange={(event) => setMemberInfo({ ...memberInfo, [event.target.name]: event.target.value })}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                name="confirm_pwd"
                label="確認密碼"
                type="password"
                id="confirm_pwd"
                value={memberInfo.confirm_pwd}
                onChange={(event) => setMemberInfo({ ...memberInfo, [event.target.name]: event.target.value })}
            />
            <Button
                onClick={onResetPassword}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                重設密碼
            </Button>
        </Box>
    )
}

export default React.memo(PasswordResetting);