import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import React, { useState } from "react";
import Address from "../components/address/Address";
import EnvCategoryAC from "../components/env-category/EnvCategoryAC";
import { useAPI } from "../context/APIContext";

export const Condition = (props) => {
    const { handleApiParam, handleApiMutiParam } = useAPI();
    const [formData, setFormData] = useState({
        category: null,
        name: null,
        has_coordinate: false,
        has_google_place_id: false,
        county: null,
        district: null,
        address: null,
        page_size: 20,
        page_index: 0
    });
    const GridColumnSpacingAddr = { xs: 1, sm: 2, md: 3 }
    const GridItemSizeAddr = { county: { xs: 12, sm: 3, md: 3 }, district: { xs: 12, sm: 3, md: 3 }, address: { xs: 12, sm: 3, md: 3 } }
    const GridColSpacingEnvCategory = { xs: 1, sm: 2, md: 3 }
    const GridItemSizeEnvCategory = { xs: 12, sm: 6, md: 6 }

    const handleChange = (event) => {
        handleApiParam(event, formData, setFormData);
    };

    const handleMuitChange = (values) => {
        handleApiMutiParam(values, formData, setFormData);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        props.handleSearch(formData);
    }

    const handleCreate = (event) => {
        event.preventDefault();
        props.onShowCreatedDialog();
    }

    return (
        <Grid component="form" container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={3}>
            <Grid item xs={12} sm={6} md={6} >
                <EnvCategoryAC
                    handleMuitChange={handleMuitChange}
                    GridColSpacing={GridColSpacingEnvCategory}
                    GridItemSize={GridItemSizeEnvCategory}
                />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
                <FormControl fullWidth>
                    <FormControlLabel
                        control={<Checkbox inputProps={{ name: "has_coordinate" }} />}
                        value={formData.has_coordinate}
                        //checked={item.checked}
                        onChange={handleChange}
                        label="有座標"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
                <FormControl fullWidth>
                    <FormControlLabel
                        control={<Checkbox inputProps={{ name: "has_google_place_id" }} />}
                        value={formData.has_google_place_id}
                        //checked={item.checked}
                        onChange={handleChange}
                        label="有 Google ID"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Address
                    handleChange={handleChange}
                    handleMuitChange={handleMuitChange}
                    GridColumnSpacing={GridColumnSpacingAddr}
                    GridItemSize={GridItemSizeAddr}
                />
            </Grid>
            <Grid item xs={12} sm={1} md={1}>
                <Button variant="contained" onClick={handleSubmit}>確認</Button>
            </Grid>
            <Grid item xs={12} sm={1} md={1}>
                <Button
                    variant="contained"
                    sx={{ backgroundColor: '#FFD600', color: 'white', '&:hover': { backgroundColor: 'darkred' } }}
                    onClick={handleCreate}
                >
                    新增
                </Button>
            </Grid>
        </Grid>
    )
}

export default React.memo(Condition);