import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import Address from "../components/address/Address";
import EnvCategoryAC from "../components/env-category/EnvCategoryAC";
import { useAPI } from "../context/APIContext";
import { LoadingOverlay } from '../components/loading/Loading';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export const EditDialog = ({ editRow, dialogOpen, handleClose, handleUpdate }) => {
    const { handleApiParam, handleApiMutiParam, loading } = useAPI();
    const [formData, setFormData] = useState(null);
    const GridColumnSpacingAddr = { xs: 1, sm: 2, md: 3 }
    const GridItemSizeAddr = { xs: 12, sm: 4, md: 4 }
    const GridColSpacingEnvCategory = { xs: 1, sm: 2, md: 3 }
    const GridItemSizeEnvCategory = { county: { xs: 12, sm: 6, md: 6 }, district: { xs: 12, sm: 6, md: 6 }, address: { xs: 12, sm: 6, md: 6 } }

    useEffect(() => {
        setFormData(editRow);
    }, [editRow]);

    const handleChange = (event) => {
        handleApiParam(event, formData, setFormData);
    };

    const handleMuitChange = (values) => {
        handleApiMutiParam(values, formData, setFormData);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        handleUpdate(formData);
    }

    return (
        <>
            {formData &&
                <BootstrapDialog
                    maxWidth="lg" //'xs', 'sm', 'md', 'lg', 'xl' 或 false
                    fullWidth={true}
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={dialogOpen}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        編輯
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        {loading && <LoadingOverlay />}
                        <Grid component="form" container rowSpacing={{ xs: 1, sm: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={3}>
                            <Grid item xs={12} sm={12} md={12}>
                                <input type='hidden' name='id' value={formData.id} ></input>
                                <p>ID:{formData.id}</p>
                            </Grid>
                            <Grid item xs={12} sm={3} md={4}>
                                <FormControl fullWidth>
                                    <TextField fullWidth
                                        name="lat"
                                        label="緯度"
                                        defaultValue={formData.lat}
                                        onChange={handleChange}
                                        placeholder="23.100"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} md={4}>
                                <FormControl fullWidth>
                                    <TextField fullWidth
                                        name="lng"
                                        label="經度"
                                        defaultValue={formData.lng}
                                        onChange={handleChange}
                                        placeholder="121.100"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3} md={4}></Grid>
                            <Grid item xs={12} sm={3} md={8}>
                                <EnvCategoryAC
                                    defCategory={formData.category}
                                    defName={formData.name}
                                    handleMuitChange={handleMuitChange}
                                    GridColSpacing={GridColSpacingEnvCategory}
                                    GridItemSize={GridItemSizeEnvCategory}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} md={4}>
                                <FormControl fullWidth>
                                    <TextField fullWidth
                                        name="subname"
                                        label="分店名稱"
                                        defaultValue={formData.subname}
                                        onChange={handleChange}
                                        placeholder=""
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Address
                                    defCounty={formData.county}
                                    defDistrict={formData.district}
                                    defAddress={formData.address}
                                    handleChange={handleChange}
                                    handleMuitChange={handleMuitChange}
                                    GridColumnSpacing={GridColumnSpacingAddr}
                                    GridItemSize={GridItemSizeAddr}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus type="submit" onClick={handleSubmit}>
                            更新
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            }
        </>
    )
}

export default React.memo(EditDialog);